// import Checkers from './checkers';
// import Chess from './chess/';
// import Connect4 from './connect4/';
// import MusicPlayer from './musicplayer';
// import Solitaire from './solitaire/';
// import Sudoku from './sudoku/index';
// import BookReader from './bookreader/index';

import { sendMessage } from "../../../game_entry_files/sendMessage";

let _tempData;

/**
 * @namespace se_games
 */
class se_games
{
    constructor()
    {
        this._gameClassPool = {};
        this._tempConfig = null;

        this.onImport = this._onImport.bind(this);
        this.onImportRejected = this._onImportRejected.bind(this);
    }

    /**
     * @function se_games#loadGame
     * @param data
     */
    loadGame(data)
    {
        _tempData = data;

        // if class already loaded then use that;
        if (this._gameClassPool[data.name])
        {
            new this._gameClassPool[data.name](_tempData.config);

            return;
        }

        // import the game class
        // import(data.path).then(this.onImport, this.onImportRejected);

        /**
         *
         * For now we need to hard-code dynamic import paths for webpack, until we support native es6
         *
         * */
        switch (data.path)
        {
            case "./othello/":
                import("./othello/").then(this.onImport, this.onImportRejected);
                break;

            case "./solitaire/":
                import("./solitaire/").then(this.onImport, this.onImportRejected);
                break;

            case "./connect4/":
                import("./connect4/").then(this.onImport, this.onImportRejected);
                break;

            case "./chess/":
                import("./chess/").then(this.onImport, this.onImportRejected);
                break;

            case "./checkers/":
                import("./checkers/").then(this.onImport, this.onImportRejected);
                break;

            case "./sudoku/":
                import("./sudoku/").then(this.onImport, this.onImportRejected);
                break;

            case "./sudoku_monsters/":
                import("./sudoku/").then(this.onImport, this.onImportRejected);
                break;

            case "./musicplayer/":
                import("./musicplayer/").then(this.onImport, this.onImportRejected);
                break;

            case "./moleminers/":
                import("./moleminers/").then(this.onImport, this.onImportRejected);
                break;

            case "./eyedrive/":
                import("./eyedrive/").then(this.onImport, this.onImportRejected);
                break;

            case "./bookreader/":
                import("./bookreader/").then(this.onImport, this.onImportRejected);
                break;

            case "./gazeygolf/":
                import("./gazeygolf/").then(this.onImport, this.onImportRejected);
                break;

            case "./snakesandladders/":
                import("./snakesandladders/").then(this.onImport, this.onImportRejected);
                break;

            case "./opendrive/":
                import("./opendrive/").then(this.onImport, this.onImportRejected);
                break;
        }
    }

    _onImport(result)
    {

        console.log('DEV: import was: ', result)

        const gameClass = result.default;

        // console.log(gameClass);

        this._gameClassPool[gameClass.name] = gameClass;

        if (_tempData)
        {
            new gameClass(_tempData.config ? _tempData.config : null);
            // talk to parent window and let know game has loaded
            // window.parent.postMessage()
            let message = {action: 'gameLoaded', name: gameClass.name}
            sendMessage(message)
        }

        _tempData = null;
    }

    _onImportRejected(reason)
    {
        console.log("import rejected", reason);
    }
}

const _se_games = new se_games();

export default _se_games;
